.InsuranceCardContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    background-color: white;
    padding: 0px 22px 34px 22px;
}

.InsuranceTextContainer {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}
