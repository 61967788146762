.ServiceView {
}

.ServicesView .SubPageNavbar {
    margin-bottom: 56px;
}

.ServicesView .BannerMoVeInRenewal {
    border-radius: 8px;
    padding: 16px;
    color: var(--secondary-color);
    background-color: var(--light-success-color);
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}

.ServicesView .BannerBundleDiscount {
    padding: 16px;
    color: var(--secondary-color);
    background-color: var(--light-success-color);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 30px;
    -moz-box-shadow: 0 0 8px #b9b9b9;
    -webkit-box-shadow: 0 0 8px #b9b9b9;
    box-shadow: 0 0 8px #b9b9b9;
}

.ServicesView .DiscountRed {
    color: #f8837e;
}

.ServicesView .CardContent .MyPageButton {
    align-self: center;
}
