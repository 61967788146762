.footer {
    text-align: center;
    align-self: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
}

.footerSpacer {
    flex: 1 1 0;
}
