/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.App {
    min-height: 100vh;
    background-color: #ffffff;
    display: flex;
}

.App .LeftMenu {
    min-width: var(--permanent-left-width);
    width: var(--permanent-left-width);
}

.App .AppContent {
    flex-grow: 1;
}

/**/

.App.desktop {
    background-color: var(--magic1-color);
}

.App.large {
    background-color: var(--magic1-color);
}

.App.desktop .PermanentLeft {
    background-color: white;
}

.App.large .PermanentLeft {
    background-color: white;
}

.App.small {
}

.App.extrasmall {
}

.App.desktop .AppContent {
    max-width: 1224px;
    margin: 56px auto;
}


.App.large .AppContent {
    max-width: 1224px;
    margin: 56px auto;
}

@media (max-width: 1548px) {
    /*calc(1272px + var(--permanent-left-width) + 12px);*/
    .App.desktop .AppContent {
        margin: 56px 24px;
    }

    .App.large .AppContent {
        margin: 56px 24px;
    }

}

.App.small .AppContent,
.App.extrasmall .AppContent {
    padding: 40px 16px;
    width: 100%;
}

.App.small .AppContent .SmallHeader,
.App.extrasmall .AppContent .SmallHeader {
    margin-bottom: 40px;
}

.App.small .AppContent .SmallMenu,
.App.extrasmall .AppContent .SmallMenu {
    margin-bottom: 40px;
}

.BlockingContentModal {
}

.BlockingContentModal .close,
.BlockingContentModal .top {
    display: none;
}

.BlockingContentModal .MyPageButton {
    margin: 8px;
}

.large.BlockingContentModal .MyPageButton {
    width: initial;
}

.desktop.BlockingContentModal .MyPageButton {
    width: initial;
}

.BlockingContentModal .PrivacyTitle {
    color: var(--black-color);
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
}

.BlockingContentModal .Subtitle {
    opacity: 0.4;
    font-size: 16px;
    color: var(--black-color);
    font-weight: 400;
    letter-spacing: -0.6px;
    margin-bottom: 20px;
}

.BlockingContentModal {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.4px;
}

.BlockingContentModal .AgreementItem {
    opacity: 0.6;
}
