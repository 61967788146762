.InsuranceBannerContainer {
    min-height: 139px;
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--air-salmon-color);
    box-shadow: 0 10px 30px rgba(23, 23, 23, 0.07);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--black-color);
}

.InsuranceBannerTitle {
    width: 254px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--air-salmon-color);
    background-color: var(--air-salmon-color);
    color: var(--white-color);
    margin-bottom: 25px;
}

.InsuranceBannerMainSectionMobile {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 31px;
}

.InsuranceBannerMainSection {
    width: 100%;
    max-width: 100%;
    height: 100%;
    align-self: flex-start;
    display: flex;
    gap: 18px;
    align-items: center;
    padding-left: 42px;
}

.InsuranceBannerInnerSection {
    align-self: flex-start;
}

.InsuranceBannerMainSection .RCAutoSection {
    color: var(--black-color);
    font-size: 14px;
    flex: 1 1 0;
}

.InsuranceBannerMainSection .RCAutoSection span:nth-child(3) {
    line-height: 2;
}

.InsuranceBannerImg {
    width: 45px;
    height: 40px;
    border-radius: 50%;
}

.InsuranceBannerLogo {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    padding-right: 28px;
    padding-bottom: 17px;
}

.Label {
    color: var(--air-salmon-color);
    font-weight: 700;
    font-size: 12px;
}
