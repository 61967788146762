.Footer {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    align-self: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
}

.Footer .FooterLink {
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
    color: inherit;
    text-decoration: underline;
}

.FooterSpacer {
    flex: 1 1 0;
}
