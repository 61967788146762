@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif;
  --white-color: #ffffff;
  --black-color: #191d3a; /* 25, 29, 58 */
  --grey-black-20-color: rgba(25, 29, 58, 0.2);
  --action-primary-color: #56d3cb;
  --light-primary-color: #d2f4ed;
  --action-success-color: #00f7bf;
  --light-success-color: #d2f4ed;
  --action-danger-color: #ff5d58;
  --light-danger-color: #fbe1e1;
  --action-info-color: #E6E6E6;
  --light-info-color: #d6f4f7;
  --action-warning-color: #ffb307;
  --light-warning-color: #f8f5d5;
  --action-custom-color: #56d3cb;
  --light-custom-color: #e7e7f9;
  --grey-semi-color: #e0e2e9;
  --grey-light-color: #eeeff1;
  --grey-light-color-50: rgba(238,239,241,0.5);
  --grey-grey-color: #c5c4cf;
  --grey-dark-color: #9393a0;
  --magic1-color: #f5f9fb;
  --action-brand-color: #2bb5ae;
  --action-grey-color: #9393a0;
  --light-grey-color: #ceced4;
  --action-delete-color: #FA8586;
  --action-gray-color: #B1B8B7;
  --light-delete-color: #ceced4;

  --secondary-color: #2fa0a0; /*rgb 86, 219, 209*/
  --tertiary-color: #f7fcfc; /*rgb 232, 255, 255*/

  --red-dtc-color: #ff5d58;
  --orange-dtc-color: #ffb307;
  --battery-warning-color: #ffb307;
  --alarm-color: #a175fc;
  --maintenance-color: #3195fe;
  --air-salmon-color: #f8837e;

  --assistance-icon-container-color: #FFFBED;
  --assistance-icon-color: #FFE074;

  --yellow: #ffdd07;
  --green: #22c437;

  --grey: #c9c9c9;
  --grey-2: #ceced4;
  --light-grey: #dcdcdc;
  --light-grey-2: #e6e6e6;
  --light-grey-3: #efeff1;
  --light-grey-4: #f0f0f0;
  --dark-grey: #6f7070;
  --dark-grey-2: #747575;
  --heliotrope: #A175FC;

  --bg-color: var(--light-grey-3);
  --bg-color-2: #202020;
  --bg-color-2-a05: rgba(32, 32, 32, 0.5);

  --engine-on: #2fa0a0;
  --engine-off: #819e9e;
  --lock-on: #53b3e3;
  --lock-off: #819e9e;

  --header-height: 60px;

  --selected-color: #49c6be;
  --disabled-color: #6f7070;
  --selected-tab-color: #56d3cb;

  --subheader-height: 100px;
  --health-filter-height: 80px;

  --header-with-subheader-height: calc(var(--header-height) + var(--subheader-height));

  --button-border-radius: 18px;
  --diagnostics-filters-offset: -80px;





  --permanent-left-width: 264px;
}

.AirModal {
  position: relative;
}

.AirModal .ant-modal-content {
  border-radius: 4px;
  background-color: #f9f9fb;
}

.AirModal .close {
  position: absolute;
  right: 15px;
  top: 17px;
  display: flex;
  align-content: center;
  cursor: pointer;
}

.AirModal .buttons {
  display: flex;
  align-content: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 16px;
}

.AirModal .buttons > div {
  display: flex;
  align-content: center;
}

.AirModal .top {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--action-primary-color);
  height: 113px;
  margin-top: 10px;
}

.AirModal .top .title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.AirModal .top .subtitle {
  color: var(--bg-color-2);
  font-size: 16px;
}

.AirModal .content {
  padding: 30px 16px 50px 16px;
}

.MyPageButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--black-color);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  cursor: pointer;
  width: 100%;
  border: none;
}

.MyPageButton.disabled {
  cursor: auto;
}

.MyPageButton .MyPageButtonInner {
  display: flex;
  align-items: center;
}

.SmallDivision {
  font-size: 16px;
  color: #000;
  border-radius: 8px;
  background: var(--action-info-color);
  height: 48px;
  padding: 12px 16px;
  display: flex;
}

.SmallDivision > *:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
}

.SmallDivision > *:nth-child(2) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.LabelInput {
  color: var(--black-color);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.LabelInput .ant-input {
  padding: 4px 16px;
}

.LabelInput .ant-input, .LabelInput .ant-select-selection {
  border-radius: 8px;
  background-color: white;
  color: var(--black-color);
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
}

.LabelInput .ant-input-disabled {
  color: var(--grey-grey-color);
}

.LabelInput.has-error .ant-input {
  border-color: var(--action-danger-color);
}

.LabelInput > *:first-child:not(:last-child) {
  margin-bottom: 8px;
}

.LabelInputExplain {
  margin-top: 2px;
  color: var(--action-danger-color);
}

.ProfileViewForm {
}

.ProfileViewForm .WelcomeMessage.ant-alert.ant-alert-info {
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: 40px;
    color: #191d3a;
}

.ProfileViewForm .WelcomeMessage.ant-alert.ant-alert-info .ant-alert-message {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
    margin-bottom: 4px;
}

.ProfileViewForm .WelcomeMessage.ant-alert.ant-alert-info .ant-alert-description {
    opacity: 0.4;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.4px;
    line-height: 24px;
    margin: 0;
}

@media (max-width: 840px) {
    .ProfileViewForm .ant-col {
        width: 100%;
    }
}

.ProfileViewForm .SmallDivision {
    margin-bottom: 40px;
}

.small .ProfileViewForm .SmallDivision,
.extrasmall .ProfileViewForm .SmallDivision {
    margin-bottom: 16px;
}

.ProfileViewForm .ProfileAlert {
    margin-bottom: 20px;
}

.StateSelector {
    color: var(--black-color);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 20px;
}

.StateSelector .ant-select-selection {
    background-color: white;
    border-radius: 8px;
    color: var(--black-color);
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 24px;
    padding: 0 16px;
}

.StateSelector .ant-select-selection__rendered {
   margin-left: 0;
}

.StateSelector .ant-select-disabled .ant-select-selection {
    color: var(--grey-grey-color);
}

.StateSelector.has-error .ant-select-selection {
    border-color: var(--action-danger-color);
}

.StateSelector > *:first-child:not(:last-child) {
    margin-bottom: 8px;
}

.LabelInputExplain {
    margin-top: 2px;
    color: var(--action-danger-color);
}

.ProvinceSelector {
    color: var(--black-color);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 20px;
}

.ProvinceSelector .ant-select-selection {
    background-color: white;
    border-radius: 8px;
    color: var(--black-color);
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 24px;
    padding: 0 16px;
}

.ProvinceSelector .ant-select-selection__rendered {
   margin-left: 0;
}

.ProvinceSelector .ant-select-disabled .ant-select-selection {
    color: var(--grey-grey-color);
}

.ProvinceSelector.has-error .ant-select-selection {
    border-color: var(--action-danger-color);
}

.ProvinceSelector > *:first-child:not(:last-child) {
    margin-bottom: 8px;
}

.LabelInputExplain {
    margin-top: 2px;
    color: var(--action-danger-color);
}

.CitySelector {
    color: var(--black-color);
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 20px;
}

.CitySelector .ant-select-selection {
    background-color: white;
    border-radius: 8px;
    color: var(--black-color);
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 24px;
    padding: 0 16px;
}

.CitySelector .ant-select-selection__rendered {
   margin-left: 0;
}

.CitySelector .ant-select-disabled .ant-select-selection {
    color: var(--grey-grey-color);
}

.CitySelector.has-error .ant-select-selection {
    border-color: var(--action-danger-color);
}

.CitySelector > *:first-child:not(:last-child) {
    margin-bottom: 8px;
}

.LabelInputExplain {
    margin-top: 2px;
    color: var(--action-danger-color);
}

.MyPageAlert {
}
.ChangePasswordForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 30px;
}

.ChangePasswordForm .has-error .ant-form-explain {
  color: var(--black-color);
  opacity: 0.9;
}

@media (max-width: 840px) {
  .ChangePasswordForm  {
    flex-direction: column;
  }
  .SubmitContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 100%;
  }
  .SubmitContainer .ant-btn {
    max-width: 75px;
  }
  .ant-form-explain {
    max-width: 330px;
  }
}

.SubmitContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 75px;
  margin-top: 32px;
}

@media (min-width: 840px) {
   .ant-form-explain {
    max-width: 250px;
  }
}
.MyPageSelector {
  color: var(--black-color);
  border: 1px solid var(--grey-semi-color);
  background-color: white;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.MyPageSelector .ant-select {
  xheight: 24px;
  color: var(--black-color);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.52px;
  xline-height: 24px;
}

.MyPageSelector-lg .ant-select {
  xheight: 32px;
  color: var(--black-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.7px;
  xline-height: 32px;
}

.MyPageSelector .ant-select-selection {
  background: #ffffff;
  border: none;
}

.MyPageSelectorDropdown {
  background-color: #ffffff;
}

.MyPageSelector > *:first-child {
  margin-left: 16px;
}

.MyPageSelector > *:last-child {
  margin-right: 16px;
}

.MyPageSelector-lg > *:first-child {
  margin-left: 24px;
}

.MyPageSelector-lg > *:last-child {
  margin-right: 24px;
}

.MyPageSelector-lg {
  height: 64px;
}

.ReportNotificationWithActions {
}

.ReportNotificationWithActions .close {
  display: none;
}

.ReportNotificationWithActions .top {
  display: none;
}

.ReportNotificationWithActions.extrasmall,
.ReportNotificationWithActions.small {
  width: 90vw;
}

.ReportNotificationWithActions.desktop {
  width: 500px;
}

.ReportNotificationWithActions {
  display: inline-block;
}

.ReportNotificationWithActions .ant-table-small {
  border-radius: 10px;
  background-color: var(--light-grey-4);
  border: 1px solid var(--grey);
}

.ReportNotificationWithActions .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--grey);
}

.ReportNotificationWithActions .SlidePrompt {
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(1) {
  margin-bottom: 20px;
  background-color: var(--light-danger-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(1) > *:nth-child(1) {
  color: var(--action-danger-color);
  margin-left: 4px;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(1) > *:nth-child(2) {
  flex: 1 0;
  margin: 4px;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(2) {
  margin-bottom: 20px;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(3) {
  display: flex;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(3) > * {
  flex: 1 0;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(3) > *:not(:last-child) {
  margin-right: 4px;
}

.ReportNotificationWithActions .SlidePrompt > *:nth-child(3) > *:not(:first-child) {
  margin-left: 4px;
}

.ReportNotificationWithActions .SlidePrompt .Action {
  font-size: 22px;
  font-weight: bold;
}

.ReportNotificationWithActions .SlidePrompt .ActionSub {
  font-size: 11px;
}

.ReportNotificationWithActions .NotificationsHistory {
  display: inline-block;
}

.ReportNotificationWithActions .NotificationsHistory .ant-table-small {
  border-radius: 10px;
  background-color: var(--light-grey-4);
  border: 1px solid var(--grey);
}

.ReportNotificationWithActions .NotificationsHistory .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--grey);
}
.ProfileView {
}

.ProfileNotification .top {
  display: none;
}

.ProfileNotification.extrasmall,
.ProfileNotification.small {
  width: 90vw;
}

.ProfileNotification.desktop {
  width: 500px;
}

.ProfileNotificationArchive {
  display: inline-block;
}

.ProfileNotificationArchive .ant-table-small {
  border-radius: 10px;
  background-color: var(--light-grey-4);
  border: 1px solid var(--grey);
}

.ProfileNotificationArchive .ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--grey);
}

.PermanentGroup {
  margin-top: 12px;
  cursor: pointer;
}

.PermanentGroup .GroupTitle {
  height: 36px;
  color: var(--black-color);
  font-size: 16px;
  letter-spacing: -0.4px;
  margin-left: 24px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.PermanentGroup .GroupContainer {
  max-height: 0;
  overflow: hidden;
  transition: all .3s ease-out;
}

.PermanentGroup .GroupContainer.open {
  max-height: 300px;
}

.PermanentGroup .GroupTitle.active {
  font-weight: bold;
}

.PermanentGroup .ResourceIcon {
  width: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PermanentResource {
  height: 24px;
  margin-bottom: 12px;
  display: flex;
}

.PermanentResource .ResourcePrefix {
  width: 24px;
  display: flex;
}

.PermanentResource .ResourceIcon {
  width: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PermanentResource .ResourceTitle {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PermanentResource .ResourceIcon,
.PermanentResource .ResourceTitle {
  color: var(--grey-black-20-color);
  cursor: pointer;
}
.PermanentResource.Active .ResourceIcon,
.PermanentResource.Active .ResourceTitle {
  color: var(--action-primary-color);
}

.PermanentResource .ResourceSuffix {
  width: 24px;
}

.PermanentLeft {
}
.MyPageDesktopContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.SmallHeader {
  display: flex;
  justify-content: space-between;
}

.SmallHeader > * {
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MyPageMobileContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.MobileLeftMenu .ant-drawer-content {
    background-color: white;
    display: flex;
}

.MobileLeftMenu .ant-drawer-wrapper-body {
    flex-grow: 1;
}

.MobileLeftMenu .ant-drawer-body {
    padding: 0;
}

.MyPageRadioButton {
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton.ant-radio-button-wrapper {
  background-color: transparent;
  display: flex;

  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;

  color: var(--action-primary-color);
  border: none;
  border-radius: 8px;
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ffffff;
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton:not(.ant-radio-button-wrapper-checked):first-child {
  border-left: none;
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: var(--action-primary-color);
}

.MyPageRadioGroup:not(.MyPageRadioGroup-lg) .MyPageRadioButton > *:not(.ant-radio-button) {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}



.MyPageRadioGroup-lg .MyPageRadioButton.ant-radio-button-wrapper {
  background-color: transparent;
  display: flex;

  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;

  color: var(--action-primary-color);
  border-color: var(--action-primary-color);
  box-shadow: -1px 0 0 0 var(--action-primary-color);
}

.MyPageRadioGroup-lg .MyPageRadioButton.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ffffff;
}

.MyPageRadioGroup-lg .MyPageRadioButton:not(.ant-radio-button-wrapper-checked):first-child {
  border-left: none;
}

.MyPageRadioGroup-lg .MyPageRadioButton.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.MyPageRadioGroup-lg .MyPageRadioButton.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: var(--action-primary-color);
}

.MyPageRadioGroup-lg .MyPageRadioButton > *:not(.ant-radio-button) {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.MyPageRadioGroup-lg .MyPageRadioButton:first-child {
  border-radius: 8px 0 0 8px;
}

.MyPageRadioGroup-lg .MyPageRadioButton:last-child {
  border-radius: 0 8px 8px 0;
}

.MyPageRadioGroup {
  display: flex;
}

.MyPageRadioGroup > * {
  flex: 1 0;
}

.VehicleSelector.MyPageSelector{
    margin-bottom: 56px;
}

.extrasmall .VehicleSelector.MyPageSelector,
.small .VehicleSelector.MyPageSelector {
    margin-bottom: 40px;
}


.VehicleSelector.MyPageSelector .ant-select.ant-select-enabled.ant-select-no-arrow {
    overflow: hidden;
}

.VehicleSelector.MyPageSelector .ant-select-selection-selected-value {
    text-overflow: ellipsis;
}

.ant-modal.EmbeddedDocumentModal {
    border-radius: 8px;
    background-color: #ffffff;
    position: relative;
    padding: 0;
}

.ant-modal.EmbeddedDocumentModal.AirModal .top {
    display: none;
}

.ant-modal.EmbeddedDocumentModal .title {
    height: 32px;
    color: var(--black-color);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
}

.ant-modal.EmbeddedDocumentModal .content {
    font-size: 10px;
    padding: 10px;
}

.PrivacyUpdate .MyPageButton {
    margin: 8px;
}

.desktop.PrivacyUpdate .MyPageButton {
    width: initial;
}

.PrivacyUpdate .PrivacyTitle {
    color: var(--black-color);
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
}

.PrivacyUpdate .Subtitle {
    opacity: 0.4;
    font-size: 16px;
    color: var(--black-color);
    font-weight: 400;
    letter-spacing: -0.6px;
    margin-bottom: 20px;
}

.PrivacyUpdate {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.4px;
}


.PrivacyUpdate .AgreementItem {
    opacity: 0.6;
}

p {
    margin-bottom: 0px;
}

.LinkToDocument {
    opacity: 0.6;
    /*font-weight: 600;*/
    font-size: 22px;
}
.DocumentsView .SubPageNavbar {
  margin-bottom: 56px;
}

.extrasmall .DocumentsView .SubPageNavbar,
.small .DocumentsView .SubPageNavbar {
  margin-bottom: 40px;
}

.App.desktop .DocumentsView .SectionContent {
  display: flex;
}

.App.desktop .DocumentsView .SectionContent > * {
  flex: 1 0;
}

.ant-modal.EmbeddedDocumentModal {
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  padding: 0;
}

.ant-modal.EmbeddedDocumentModal.AirModal .top {
  display: none;
}

.ant-modal.EmbeddedDocumentModal .title {
  height: 32px;
  color: var(--black-color);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.ant-modal.EmbeddedDocumentModal .content {
  font-size: 10px;
  padding: 10px;
}

.PrivacyUpdate .MyPageButton {
  margin: 8px;
}

.desktop.PrivacyUpdate .MyPageButton {
  width: initial;
}

.PrivacyUpdate .PrivacyTitle {
  color: var(--black-color);
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 32px;
}

.PrivacyUpdate .Subtitle {
  opacity: 0.4;
  font-size: 16px;
  color: var(--black-color);
  font-weight: 400;
  letter-spacing: -0.6px;
  margin-bottom: 20px;
}

.PrivacyUpdate {
  color: var(--black-color);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.4px;
}


.PrivacyUpdate .AgreementItem {
  opacity: 0.6;
}

.DocumentsView .FilterRow {
  margin-bottom: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.DocumentsView .FilterRow.mobile {
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.DocumentsView .FilterRow.mobile > * {
  width: 100% !important;
  padding-bottom: 10px;
}

.AgendaTable td.column-actions.document-buttons a {
  color: rgba(0, 0, 0, 0.65) !important;
  text-decoration: underline !important;
}

.align-right {
  float: right;
  clear: both;
}

.desktop.CustomDocumentFields {
  display: flex;
  flex-direction: row;
}

.desktop.CustomDocumentFields > * {
  width: 0;
  flex-grow: 1;
  margin: 20px 20px;
}

.CustomDocumentFields > * {
  margin: 20px 0;
}

.desktop.CustomDocumentButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.desktop.CustomDocumentButtons > *:nth-child(2) {
  margin-left: 20px;
}

.CustomDocumentButtons .MyPageButton {
  margin-bottom: 20px;
}

.desktop.CustomDocumentButtons .MyPageButton {
  max-width: 150px;
}

.document-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 16px;
  background-color: var(--action-primary-color);
  color: white;
  height: 32px;
  max-width: 150px;
}

.document-file-upload input[type="file"] {
  width: 0;
}

.AddDocumentButton {
  margin: 10px;
  color: var(--action-primary-color);
}

.DocumentsView .document-buttons {
  color: var(--action-info-color);
}

.LicenseView {
}
.LicenseView {
}
.PersonalDocumentsView {
}

@media (min-width: 840px) {
  .PersonalDocumentsView {
  }

  .PersonalDocumentsView .FieldsRow {
    display: flex;
  }

  .PersonalDocumentsView .FieldsRow > * {
    flex: 1 0;
  }

  .PersonalDocumentsView .FieldsRow > *:not(:first-child) {
    margin-left: 24px;
  }

  .ButtonContainer {
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    justify-content: space-evenly;
  }
}

.LicenseTypeSelector {
  color: var(--black-color);
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.LicenseTypeSelector .ant-select-selection {
  background-color: white;
  border-radius: 8px;
  color: var(--black-color);
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
  padding: 0 16px;
}

.PersonalDocumentsView .buttonCenter {
  display: flex;
  justify-content: center;
}

.UploadButton {
  min-width: 100%;
}

.ant-upload.ant-upload-select{
  min-width: 100%;
}

.MobileVehicleView {
}
.DesktopVehicleView {
}
.DesktopVehicleView {
}
.VehicleView {
}

.VehicleView.desktop {
}

.VehicleView.desktop .FieldsRow {
  display: flex;
}

.VehicleView.desktop .FieldsRow > * {
  flex: 1 0;
}

.VehicleView.desktop .FieldsRow > *:not(:first-child) {
  margin-left: 24px;
}

.ServiceView {
}

.ServicesView .SubPageNavbar {
    margin-bottom: 56px;
}

.ServicesView .BannerMoVeInRenewal {
    border-radius: 8px;
    padding: 16px;
    color: var(--secondary-color);
    background-color: var(--light-success-color);
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
}

.ServicesView .BannerBundleDiscount {
    padding: 16px;
    color: var(--secondary-color);
    background-color: var(--light-success-color);
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 30px;
    box-shadow: 0 0 8px #b9b9b9;
}

.ServicesView .DiscountRed {
    color: #f8837e;
}

.ServicesView .CardContent .MyPageButton {
    align-self: center;
}

.ProductCard {
    position: relative;
    background: white;
    border-radius: 12px;
    width: 270px;
    height: 380px;
    overflow: hidden;
    margin: 16px auto;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--grey-semi-color);
}

.ProductCard img {
    width: 100%;
    height: auto;
}

.ProductCard .CardContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    background-color: white;
    padding: 16px 24px 48px 24px;
}

.ProductCard .LogoContainer {
    align-self: center;
}

.ProductCard .TextContainer {
    flex-grow: 1;
    position: relative;
}

.ProductCard .SubscribeButton {
    width: 100%;
    border-radius: 8px;
    border: none;
    height: 32px;
    background-color: var(--action-primary-color);
    color: white;
}

.ProductCard h2, .ProductCard p {
    margin-bottom: 8px;
}

.ProductCard h2 {
    color: var(--black-color);
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
}


.ProductCard .InfoIcon {
    color: #4C96F6;
    position: absolute;
    right: -4px;
    top: 4px;
    cursor: pointer;
}

.CardBack {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    background-color: white;
    width: 100%;
    height: 100%;
    transition: all .5s ease-out;
}

.CardBack.closed {
    height: 32px;
}

.ProductCard .CardBackHeader {
    cursor: pointer;
    position: relative;
    text-align: center;
    font-weight: 600;
    padding: 6px 24px;
    height: 32px;
    background-color: #D2F4ED;
    color: var(--grey-dark-color);
}

.CardBack .TextContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ProductCard .BackIcon {
    position: absolute;
    right: 40px;
    top: 10px;
    cursor: pointer;
}

.ProductCard .AirClubInfoIcon {
    position: absolute;
    right: 24px;
    top: 40px;
    cursor: pointer;
}

.ProductCard .ListItemContainer {
    display: flex;
}

.ProductCard .ListIcon {
    color: var(--action-primary-color);
    margin-top: 2px;
    margin-right: 13px;
}

.ProductCard .Description,
.ProductCard .Price {
    color: var(--black-color);
}

.ProductCard .Available .Price {
    margin-bottom: 16px;
    text-align: center;
}

.ProductCard .Expiring .Price {
    color: var(--grey-dark-color);
    display: flex;
    justify-content: space-between;
}

.ProductCard .ActiveLabel {
    color: #3FDC17;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
}

.ProductCard .ExpiringLabel {
    color: var(--action-warning-color);
}

.ProductCard .Expiring .ActiveLabel,
.ProductCard .Expiring .ExpiringLabel {
    margin-bottom: 0;
}

.ProductCard .DiscountedBadge {
    position: absolute;
    z-index: 3;
    background-color: #f8837e;
    color: #fff;
    transform: rotate(-45deg);
    margin-top: 15px;
    margin-left: -35px;
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 600;
}

.ProductCard .PromoPrice {
    color: #f8837e;
    font-weight: bold;
}

.InsuranceBannerContainer {
    min-height: 139px;
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--air-salmon-color);
    box-shadow: 0 10px 30px rgba(23, 23, 23, 0.07);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--black-color);
}

.InsuranceBannerTitle {
    width: 254px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--air-salmon-color);
    background-color: var(--air-salmon-color);
    color: var(--white-color);
    margin-bottom: 25px;
}

.InsuranceBannerMainSectionMobile {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 31px;
}

.InsuranceBannerMainSection {
    width: 100%;
    max-width: 100%;
    height: 100%;
    align-self: flex-start;
    display: flex;
    grid-gap: 18px;
    gap: 18px;
    align-items: center;
    padding-left: 42px;
}

.InsuranceBannerInnerSection {
    align-self: flex-start;
}

.InsuranceBannerMainSection .RCAutoSection {
    color: var(--black-color);
    font-size: 14px;
    flex: 1 1;
}

.InsuranceBannerMainSection .RCAutoSection span:nth-child(3) {
    line-height: 2;
}

.InsuranceBannerImg {
    width: 45px;
    height: 40px;
    border-radius: 50%;
}

.InsuranceBannerLogo {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    padding-right: 28px;
    padding-bottom: 17px;
}

.Label {
    color: var(--air-salmon-color);
    font-weight: 700;
    font-size: 12px;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, var(--air-salmon-color) 90%, #0000);
    background: var(--_g) 0 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
    background-size: calc(100% / 3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0 0, 50% 50%, 100% 50%
    }
    40% {
        background-position: 0 100%, 50% 0, 100% 50%
    }
    60% {
        background-position: 0 50%, 50% 100%, 100% 0
    }
    80% {
        background-position: 0 50%, 50% 50%, 100% 100%
    }
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, var(--air-salmon-color) 90%, #0000);
    background: var(--_g) 0 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
    background-size: calc(100% / 3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0 0, 50% 50%, 100% 50%
    }
    40% {
        background-position: 0 100%, 50% 0, 100% 50%
    }
    60% {
        background-position: 0 50%, 50% 100%, 100% 0
    }
    80% {
        background-position: 0 50%, 50% 50%, 100% 100%
    }
}

.InsuranceCardContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    background-color: white;
    padding: 0px 22px 34px 22px;
}

.InsuranceTextContainer {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.Footer {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    align-self: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
}

.Footer .FooterLink {
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
    color: inherit;
    text-decoration: underline;
}

.FooterSpacer {
    flex: 1 1;
}

.SupportRegioneLombardiaView {}

.SupportRegioneLombardiaView.desktop {
    width: 288px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid var(--grey-semi-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: var(--white-color);
}

.SupportRegioneLombardiaView.desktop .title {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin-top: 20px;
}

.SupportRegioneLombardiaView.desktop .subtitle {
    width: 264px;
    height: 59px;
    opacity: 0.4;
    color: var(--black-color);
    font-family: "Work Sans";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 19.2px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SupportRegioneLombardiaView.desktop .numbers {
    display: flex;
    align-content: center;
}

.SupportRegioneLombardiaView.desktop .bubble {
    width: 103px;
    height: 103px;
    background-color: var(--action-primary-color);
    border-radius: 51px;
    margin-top: 25px;
}

.SupportRegioneLombardiaView.desktop .numbers .mobile {
    width: 206px;
    height: 33px;
    border-radius: 0 8px 8px 0;
    background-color: var(--action-primary-color);
    color: var(--white-color);
    font-size: 16px;
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-content: center;
}

.SupportRegioneLombardiaView.desktop .numbers .mobileNumber {
    display: flex;
}

.SupportRegioneLombardiaView.desktop .numbers .phoneIcon {
    width: 33px;
    height: 33px;
    border-radius: 8px 0 0 8px;
    background-color:var(--action-primary-color);
    color: var(--white-color);
    padding-top: 8px;
    padding-left: 15px;
}

.SupportRegioneLombardiaView.extrasmall,
.SupportRegioneLombardiaView.small {
    width: 288px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid var(--grey-semi-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: var(--white-color);
}

.SupportRegioneLombardiaView.extrasmall .title ,
.SupportRegioneLombardiaView.small .title {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin-top: 20px;
}

.SupportRegioneLombardiaView.extrasmall .subtitle ,
.SupportRegioneLombardiaView.small .subtitle {
    width: 264px;
    height: 59px;
    opacity: 0.4;
    color: var(--black-color);
    font-family: "Work Sans";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 19.2px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SupportRegioneLombardiaView.extrasmall .numbers,
.SupportRegioneLombardiaView.small .numbers {
    display: flex;
    align-content: center;
}

.SupportRegioneLombardiaView.extrasmall .bubble,
.SupportRegioneLombardiaView.small .bubble {
    width: 103px;
    height: 103px;
    background-color: var(--action-primary-color);
    border-radius: 51px;
    margin-top: 25px;
}

.SupportRegioneLombardiaView.extrasmall .numbers .mobile ,
.SupportRegioneLombardiaView.small .numbers .mobile {
    width: 206px;
    height: 33px;
    border-radius: 0 8px 8px 0;
    background-color: var(--action-primary-color);
    color: var(--white-color);
    font-size: 16px;
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-content: center;
}

.SupportRegioneLombardiaView.extrasmall .numbers .mobileNumber ,
.SupportRegioneLombardiaView.small .numbers .mobileNumber {
    display: flex;
}

.SupportRegioneLombardiaView.extrasmall .numbers .phoneIcon ,
.SupportRegioneLombardiaView.small .numbers .phoneIcon {
    width: 33px;
    height: 33px;
    border-radius: 8px 0 0 8px;
    background-color:var(--action-primary-color);
    color: var(--white-color);
    padding-top: 8px;
    padding-left: 15px;
}
.SupportHelpDeskView {}

.SupportHelpDeskView a{
    color: blue;
    opacity: 1;
}

.AxaLogo {
    border-radius:50%;
    max-width: 100px;
    margin-top: 20px;
}

.subtitle {
    margin-bottom: 15px;
}

.SupportHelpDeskView.desktop {
    width: 288px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid var(--grey-semi-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: var(--white-color);
}

.SupportContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SupportContainerMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SupportHelpDeskView.desktop .title {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin-top: 20px;
}

.SupportHelpDeskView.desktop .subtitle {
    width: 264px;
    height: 59px;
    opacity: 0.6;
    color: var(--black-color);
    font-family: "Work Sans";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 19.2px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SupportHelpDeskView.desktop .numbers {
    display: flex;
    align-content: center;
}

.SupportHelpDeskView.desktop .bubble {
    width: 103px;
    height: 103px;
    background-color: var(--action-primary-color);
    border-radius: 51px;
    margin-top: 25px;
}

.SupportHelpDeskView.desktop .numbers .mobile {
    width: 206px;
    height: 33px;
    border-radius: 0 8px 8px 0;
    background-color: var(--action-primary-color);
    color: var(--white-color);
    font-size: 16px;
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

.SupportHelpDeskView.desktop .numbers .mobileNumber {
    display: flex;
}

.SupportHelpDeskView.desktop .numbers .phoneIcon {
    width: 33px;
    height: 33px;
    border-radius: 8px 0 0 8px;
    background-color:var(--action-primary-color);
    color: var(--white-color);
    padding-top: 8px;
    padding-left: 15px;
    cursor: pointer;
}

.SupportHelpDeskView.extrasmall,
.SupportHelpDeskView.small {
    width: 288px;
    height: 320px;
    border-radius: 16px;
    border: 1px solid var(--grey-semi-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    background-color: var(--white-color);
    margin-top: 30px;
}

.SupportHelpDeskView.extrasmall .title ,
.SupportHelpDeskView.small .title {
    color: var(--black-color);
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.2px;
    line-height: 36px;
    margin-top: 20px;
}

.SupportHelpDeskView.extrasmall .subtitle ,
.SupportHelpDeskView.small .subtitle {
    width: 264px;
    height: 59px;
    opacity: 0.6;
    color: var(--black-color);
    font-family: "Work Sans";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 19.2px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.SupportHelpDeskView.extrasmall .numbers,
.SupportHelpDeskView.small .numbers {
    display: flex;
    align-content: center;
}

.SupportHelpDeskView.extrasmall .bubble,
.SupportHelpDeskView.small .bubble {
    width: 103px;
    height: 103px;
    background-color: var(--action-primary-color);
    border-radius: 51px;
    margin-top: 25px;
}

.SupportHelpDeskView.extrasmall .numbers .mobile ,
.SupportHelpDeskView.small .numbers .mobile {
    width: 206px;
    height: 33px;
    border-radius: 0 8px 8px 0;
    background-color: var(--action-primary-color);
    color: var(--white-color);
    font-size: 16px;
    display: flex;
    padding: 5px 5px;
    justify-content: center;
    align-content: center;
}

.SupportHelpDeskView.extrasmall .numbers .mobileNumber ,
.SupportHelpDeskView.small .numbers .mobileNumber {
    display: flex;
}

.SupportHelpDeskView.extrasmall .numbers .phoneIcon ,
.SupportHelpDeskView.small .numbers .phoneIcon {
    width: 33px;
    height: 33px;
    border-radius: 8px 0 0 8px;
    background-color:var(--action-primary-color);
    color: var(--white-color);
    padding-top: 8px;
    padding-left: 15px;
}

.days {
    max-width: 210px;
}

.ContactContainer {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    opacity: 0.8;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 25px;
}

a:link{
    font-size: 20px;
    color: darkviolet;
    text-decoration: none;
}

.AxaContactContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    opacity: 0.8;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}
.SuccessView {
    text-align: center;
}

.footer {
    text-align: center;
    align-self: center;
    font-weight: 300;
    font-size: 10px;
    line-height: 13px;
}

.footerSpacer {
    flex: 1 1;
}

.AdvertisingRCABanner-container {
    background-color: #2BB5AE;
    border-radius: 15px;
    color: white;
    text-align: center;
    padding: 32px 21px;
    font-size: 14px;
    font-weight: 300;
    width: 311px;
}

.MyPageCheckbox {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
  display: flex;
}

.MyPageCheckbox > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MyPageCheckbox > *:first-child {
  margin-right: 12px;
}

.InstallerRow {
    display: flex;
    width: 100%;
    align-items: center;
}

.InstallerCheck {
    margin-right: 8px;
    transform: scale(1.3);
}

.InstallerFields {
    text-align: left;
    width: 100%;
    margin-bottom: 16px;
}

.InstallerRow .AvailabeOBDs {
    background-color: var(--action-primary-color);
    color: white;
    text-align: center;
}

.InstallerRow .AvailabeOBDs.noOBDs{
    background-color: var(--grey);
}

.small .InstallerRow, .extrasmall .InstallerRow {
    margin-bottom: 30px;
}

.zzz_ZuoraPayment {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 5%;
}

.zzz_ZuoraPayment #z-container {
  margin-left: -150px;
  left: auto;
}

.InstallerList .SumbmitButton {
  width: 250px;
}

.small .InstallerList .SumbmitButton,
.extrasmall .InstallerList .SumbmitButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.HeaderTwo {
  color: var(--black-color);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.52px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.HeaderTwo-lg {
  color: var(--black-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.7px;
  margin-top: 56px;
  margin-bottom: 20px;
}

.InstallerPaymentView {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
}

.InstallerPaymentView .PaymentPane {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.InstallerPaymentView .MyPageCheckbox {
  margin: 10px auto;
}

.InstallerPaymentView .PaymentErrorMessage {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
  color: var(--action-danger-color);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.InstallerPaymentView .PaymentErrorMessage > *:first-child {
  margin-right: 10px;
}

.PaymentAgreements {
}
.MyPageCheckbox {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
  display: flex;
}

.MyPageCheckbox > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MyPageCheckbox > *:first-child {
  margin-right: 12px;
}

.zzz_ZuoraPayment {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 5%;
}

.zzz_ZuoraPayment #z-container {
  margin-left: -150px;
  left: auto;
}

.ZuoraPayment {
  border-radius: 8px;
  overflow: hidden;
}

.small .InstallerList .SumbmitButton,
.extrasmall .InstallerList .SumbmitButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.HeaderTwo {
  color: var(--black-color);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.52px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.HeaderTwo-lg {
  color: var(--black-color);
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.7px;
  margin-top: 56px;
  margin-bottom: 20px;
}

.InstallerPaymentView {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
}

.InstallerPaymentView .MyPageCheckbox {
  margin: 10px auto;
}

.InstallerPaymentView .PaymentErrorMessage {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  line-height: 24px;
  color: var(--action-danger-color);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.InstallerPaymentView .PaymentErrorMessage > *:first-child {
  margin-right: 10px;
}

.SlimpayIframeContainer {
  max-width: 600px;
  margin: auto;
}

.SlimpayIframe {
  padding: 0;
  border: none;
  margin: 0;
  margin-left: -5px;
  height: 510px;
}

.small .SlimpayIframe,
.extrasmall .SlimpayIframe {
  height: 600px;
}

.AirClubInfoContract{
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.AirClubInfoContract a {
  font-size: 16px;
  color: var(--black-color);
  text-decoration: none;
}

.FriendlyReminder {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  font-size: 14px;
}
.Section {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 40px
}

.Section .SmallDivision {
    border-radius: 0
}

.small .Section .SmallDivision,
.extrasmall .Section .SmallDivision
{
    border-radius: 8px;
}

.Section .SectionContent {
    background: white;
    padding: 16px;
}

.ThankYouPageView .ThankYouPageViewInner {
  color: var(--black-color);
  border: 1px solid var(--grey-semi-color);
  background-color: white;
  border-radius: 8px;
  margin-top: 40px;
  padding: 20px;
}

.ThankYouPageView .textSuccessfulPayment {
  color: var(--green);
}

.ThankYouPageView .textGoToHome {
  cursor: pointer;
  color: var(--action-info-color);
}

.ThankYouPageView .textGoToSupport {
  cursor: pointer;
  color: var(--action-warning-color);
}


/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.App {
    min-height: 100vh;
    background-color: #ffffff;
    display: flex;
}

.App .LeftMenu {
    min-width: var(--permanent-left-width);
    width: var(--permanent-left-width);
}

.App .AppContent {
    flex-grow: 1;
}

/**/

.App.desktop {
    background-color: var(--magic1-color);
}

.App.large {
    background-color: var(--magic1-color);
}

.App.desktop .PermanentLeft {
    background-color: white;
}

.App.large .PermanentLeft {
    background-color: white;
}

.App.small {
}

.App.extrasmall {
}

.App.desktop .AppContent {
    max-width: 1224px;
    margin: 56px auto;
}


.App.large .AppContent {
    max-width: 1224px;
    margin: 56px auto;
}

@media (max-width: 1548px) {
    /*calc(1272px + var(--permanent-left-width) + 12px);*/
    .App.desktop .AppContent {
        margin: 56px 24px;
    }

    .App.large .AppContent {
        margin: 56px 24px;
    }

}

.App.small .AppContent,
.App.extrasmall .AppContent {
    padding: 40px 16px;
    width: 100%;
}

.App.small .AppContent .SmallHeader,
.App.extrasmall .AppContent .SmallHeader {
    margin-bottom: 40px;
}

.App.small .AppContent .SmallMenu,
.App.extrasmall .AppContent .SmallMenu {
    margin-bottom: 40px;
}

.BlockingContentModal {
}

.BlockingContentModal .close,
.BlockingContentModal .top {
    display: none;
}

.BlockingContentModal .MyPageButton {
    margin: 8px;
}

.large.BlockingContentModal .MyPageButton {
    width: initial;
}

.desktop.BlockingContentModal .MyPageButton {
    width: initial;
}

.BlockingContentModal .PrivacyTitle {
    color: var(--black-color);
    font-weight: 600;
    letter-spacing: -0.7px;
    line-height: 32px;
}

.BlockingContentModal .Subtitle {
    opacity: 0.4;
    font-size: 16px;
    color: var(--black-color);
    font-weight: 400;
    letter-spacing: -0.6px;
    margin-bottom: 20px;
}

.BlockingContentModal {
    color: var(--black-color);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.4px;
}

.BlockingContentModal .AgreementItem {
    opacity: 0.6;
}

.desktop .OptionalServicesView .SectionContent {
  padding: 30px;
  position: relative;
}

.OptionalServicesView .ServiceDetailContainer small {
  color: var(--grey-dark-color);
}

.OptionalService h2 {
  color: var(--grey-dark-color);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}
.BannerMoVeInRenewal {
  border-radius: 8px;
  padding: 16px;
  color: var(--secondary-color);
  background-color: var(--light-success-color);
  font-weight: bold;
  text-align: center;
  margin-bottom: 3px;
}

.OptionalService h3 {
  color: var(--grey-dark-color);
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 1em;
}

.OptionalServicesView .NextButton {
  margin: 20px 0 20px auto;
  width: auto;
}

.OptionalServicesView .ant-radio-wrapper {
  margin-right: 0;
}

.OptionalServiceInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  background-color: #12548A;
  color: white;
}

.small .OptionalServiceInfo, .extrasmall .OptionalServiceInfo {
  flex-wrap: wrap;
  justify-content: center;
}

.small .OptionalServiceInfo>*, .extrasmall .OptionalServiceInfo>* {
  margin-top: 32px;
}

.small .InfoColumn, .extrasmall .InfoColumn {
  width: 100%;
}

.desktop .OptionalServiceInfo {
  min-height: 450px;
  font-size: 16px;
}

.OptionalServicesView .ListItemContainer {
  display: flex;
}

.OptionalServicesView .ListIcon {
  color: #F6CF02;
  margin-top: 2px;
  margin-right: 13px;
}

.OptionalServiceInfo h4 {
  font-size: 1.2em;
  text-transform: uppercase;
  color: #F6CF02;
}

.OptionalServiceInfo .InfoColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.OptionalServiceInfo .AxaLogoContainer {
  margin-top: 24px;
  font-size: 12px;
  text-align: right;
}

.OptionalServiceInfo .AxaLogoContainer img {
  width: 40px;
  height: auto;
  vertical-align: baseline;
}

.OptionalServicesView .ServiceInfoIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.small .OptionalServicesView .ServiceInfoIcon,
.extrasmall .OptionalServicesView .ServiceInfoIcon{
  order: -1;
}

.OptionalServicesView .ServiceInfoIcon img {
  max-width: 200px;
  height: auto;
}

.OptionalServiceSelectionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 18px;
}

.OptionalServiceSelection {
  display: flex;
}

.OptionalServiceSelection p {
  margin-right: 16px;
}

.OptionalServiceSelection .ant-checkbox-wrapper {
  margin-left: 12px;
  font-size: 18px;
}

.OptionalServicesView .OptionalServicePrivacy {
  font-size: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-dark-color);
  margin-bottom: 20px;
}

.OptionalServicesView .OptionalServicePrivacy p {
  margin: 0;
}

.OptionalServicesView .OptionalServicePrivacy h4, h4.PrivacyHeadTitle {
  font-size: 16px;
  margin-bottom: 0;
}

.ProtectionLevel {
  max-width: 500px;
  text-align: center;
  border: 3px solid #F6CF02;
  padding: 8px 40px;
  border-radius: 4px;
  margin-top: 40px;
}

.ProtectionLevel>div {
  padding: 8px 0;
}

.ProtectionLevel img {
  width: 40px;
  height: auto;
}

.ProtectionRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ProtectionBar>div {
  height: 8px;
  flex-grow: 1;
  background-color: #7EDA57;
  margin: 0 4px;
  border-radius: 2px;
}

.AirClubPrice {
  color: var(--action-primary-color);
}

.OptionalServicePrivacyCheck {
  margin-right: 8px;
}

.OptionalServicesView .AirClubInfoContract{
  display: flex;
  justify-content: flex-end;
}

.OptionalServicesView .AirClubInfoContract a {
  font-size: 16px;
  color: var(--black-color);
  text-decoration: none;
}

.OptionalServicesView .DiscountPrice {
  color: #f8837e;
  font-weight: bold;
}

.OptionalServicesView .StrikeThroughPrice {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

.OptionalServicesView .DiscountedBadge {
  z-index: 2;
  background-color: #f8837e;
  color: #fff;
  transform: rotate(50deg);
  margin-top: -15px;
  margin-right: -8px;
  width: 130px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 600;
  text-transform: uppercase;
}

.OptionalServicesView .DiscountedBadgeContainer {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 75px;
  margin-top: 2px;
}

.OptionalServicesView .OptionalServiceAssistanceContent {
  padding: 0;
  text-align: center;
  position: relative;
}

.OptionalServicesView .OptionalServiceAssistanceContent header {
  background-color: #EFEFEF;
  padding: 8px;
  margin-bottom: 24px;
}

.OptionalServicesView .OptionalServiceAssistanceContent header h2,
.OptionalServicesView .OptionalServiceAssistanceContent header h3
{
  margin-bottom: 0;
  color: var(--action-brand-color);
}

.OptionalServicesView .OptionalServiceAssistanceContent header h2 {
  font-weight: bold;
}

.OptionalServicesView .OptionalServiceAssistanceContent .ProductFeatures {
  margin: auto;
  display: table;
}

.OptionalServicesView .OptionalServiceAssistanceContent .ListItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}

.OptionalServicesView .OptionalServiceAssistanceContent .ListItemContainer .ListIcon {
  font-weight: bold;
  color: var(--action-brand-color);
  margin-right: 8px;
}

@media (min-width: 1094px) {
  .ThreeColumnView {
    display: flex;
    flex-direction: row;
  }

  .OneColumnComponent {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
  }
}

@media (max-width: 1094px) {
  .ThreeColumnView {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .OneColumnComponent {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
  }
  .ThreeColumnView > :nth-child(1) { order: 2; }
  .ThreeColumnView > :nth-child(2) { order: 1; }
  .ThreeColumnView > :nth-child(3) { order: 3; }
}

.AssistanceTitle {
  font-family: Poppins-Regular, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  color: #223239;
}

.AssistanceSubTitle {
  font-family: Poppins-Regular, sans-serif;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  color: #223239;
}

.BaseAssistanceSubTitleContainer {
  display: flex;
  justify-content: center;
}

.SvgImageContainer {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.AssistanceFeatureCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  background: #FFFFFF;
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-height: 80px;
  margin: 15px 30px;
  padding: 0 5px 0 5px;
}

.AssistanceFeatureCardContainer :nth-child(1) { flex: 1 1; }
.AssistanceFeatureCardContainer :nth-child(2) { flex: 3 1; }
.AssistanceFeatureCardContainer :nth-child(3) { flex: 1 1; }

.AssistanceFeatureOneColumnComponent {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
}

.AssistanceIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--assistance-icon-container-color);
  border-radius: 12px;
  max-width: 46px;
  width: 46px;
  max-height: 46px;
  height: 46px;
}

.AssistanceIconColor {
  color: var(--assistance-icon-color);
}

.AssistanceFeatureDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.AssistanceColumnTitle {
  font-family: Poppins-Regular, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  text-align: center;
}

.AssistanceColumnFeature {
  font-family: Poppins-Regular, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}

.AxaIconContainer {
  border-radius: 8px;
  overflow: hidden;
  max-width: 46px;
  width: 46px;
  max-height: 46px;
  height: 46px;
}

.BaseAssistanceProductPlanSelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.BaseAssistanceBuyButton {
  max-width: 335px;
  min-height: 55px;
  height: 55px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.BaseAssistanceDontBuyButton {
  width: 104px;
  min-height: 29px;
  height: 29px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.BaseAssistanceProductPlan {
  display: flex;
  align-items: center;
  margin: 10px 30px;
}

.BaseAssistancePlanPrice {
  font-family: Poppins-Regular, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
}

.ColoredPriceBaseAssistance {
  color: var(--action-brand-color);
  font-weight: 700;
}

.BaseAssistanceCheckboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10px;
}

.BaseAssistanceProceedButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BaseAssistanceProceedButtonsMobileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.BaseAssistanceDontBuyMobileButton {
  min-height: 55px;
  height: 55px;
  max-width: 104px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  white-space: normal;
}

.generalConditionsCheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReviewPaymentView .ListItemContainer {
    display: flex;
    align-items: center;
}

.ReviewPaymentView .ListIcon {
    color: #52AF61;
    margin-top: 2px;
    margin-right: 13px;
}

.ReviewPaymentView h3 {
    color: var(--action-brand-color);
    text-align: center;
}

.ServiceListItem {
    display: flex;
    font-size: 16px;
    color: var(--black-color);
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    line-height: normal;
}

.ServiceListItem:last-child {
    border-top: 1px solid black;
}

.ServiceListItem .ListItemSubtitle {
    color: var(--dark-grey);
    font-size: 0.9em;
}

.ServiceListItem .PriceContainer {
    display: flex;
    justify-content: space-between;
}

.ServiceListItem .DiscountedPrice {
    color: #f8837e;
}

.ServiceListItem .OriginalPriceDiscounted {
    text-decoration: line-through;
    margin-right: 15px;
}


.AgendaView .SmallDivision {
    text-align: center;
    background-color: var(--action-primary-color);
    border-radius: 8px;
}

.AgendaView .SectionContent {
    padding: 0;
}

.AgendaTable {
    overflow: scroll;
}

.AgendaTable .ant-table-thead > tr > th {
    background-color: var(--action-info-color);
    border: none;
    color: #000;
}

.AgendaTable .ant-table-thead > tr:first-child > th:first-child{
    border-radius: 8px 0 0 8px;
    overflow: hidden;
}

.AgendaTable .ant-table-thead > tr:first-child > th:last-child {
    border-radius: 0 8px 8px 0;
    overflow: hidden;
}

.AgendaTable .ant-table-tbody > tr > td {
    border: none;
}

.AgendaTable .MyPageButton {
    max-width: 150px;
    margin: 0 auto;
}

.AgendaTable th.column-actions,
.AgendaTable td.column-actions {
    text-align: center !important;
}

.AgendaTable td.column-actions button {
    border: none;
    background-color: transparent !important;
    color: rgba(0, 0, 0, 0.65) !important;
    text-decoration: underline !important;
}

.AgendaTable td.column-actions button.disabled {
    text-decoration: none !important;
}

.AgendaView .AgendaControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    color: var(--action-primary-color);
}

.AgendaView .AgendaControls.mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    color: var(--action-primary-color);
}

.AgendaView .AgendaControls.mobile > * {
    width: 100% !important;
    padding-bottom: 10px;
}

.align-right {
    float: right;
    clear: both;
}

.AgendaView .AgendaControls .History > a {
    -webkit-text-decoration: solid underline var(--action-primary-color);
            text-decoration: solid underline var(--action-primary-color);
}

.AgendaModal .top {
    display: none;
}

.AgendaModal h2, .AgendaModal div{
    text-align: center;
}

.AgendaModal .Content {
    padding-top: 20px;
}

.AgendaModal .Content > * {
    max-width: 200px;
}

.AgendaModal .CalendarContent {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AgendaModal .Content .MyPageButton,
.AgendaModal .CalendarContent .MyPageButton {
    margin: 20px auto 0 auto;
}

.AirRenewalView .SectionContent {
    padding: 0;
    text-align: center;
    position: relative;
}

.AirRenewalView header {
    background-color: #EFEFEF;
    padding: 8px;
    margin-bottom: 24px;
}

.AirRenewalView header h2,
.AirRenewalView header h3
{
    margin-bottom: 0;
    color: var(--action-brand-color);
}

.AirRenewalView header h2 {
    font-weight: bold;
}

.AirRenewalView .RenewalPrice {
    margin-left: 8px;
    text-align: left;
    margin-bottom: 24px ;
}

.AirRenewalView .RenewalPrice h2{
    color: var(--dark-grey);
    margin-bottom: 0;
    font-size: 2em;
    font-weight: bold;
}

.AirRenewalView .RenewalPrice h2 .taxes{
    font-size: 14px;
    font-weight: normal;
}

.AirRenewalView .RenewalPrice h3{
    color: var(--dark-grey);
    margin-bottom: 0;
    font-size: 1.2em;
    line-height: 0.7em;
    font-weight: bold;
}

.AirRenewalView .RenewalFeatures {
    margin: auto;
    display: table;
}

.AirRenewalView .ListItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
}

.AirRenewalView .ListItemContainer .ListIcon {
    font-weight: bold;
    color: var(--action-brand-color);
    margin-right: 8px;
}

.AirRenewalView .ReviewFooter {
    padding-top: 32px;
}

.desktop .AirRenewalView .ReviewFooter {
    padding: 32px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.AirRenewalView .ReviewFooter .ReviewInfo {
    color: var(--dark-grey);
    font-weight: 600;
    font-size: 0.95em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .AirRenewalView .ReviewFooter .ReviewInfo {
    margin-right: 16px;
}

.small .AirRenewalView .ReviewFooter .ReviewInfo,
.extrasmall .AirRenewalView .ReviewFooter .ReviewInfo {
    margin-bottom: 16px;
}

.AirRenewalView .ReviewFooter .ReviewInfo .ReviewFooterIcon {
    color: #FB8680;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .AirRenewalView .ReviewFooter .MyPageButton {
    width: 200px;
}

.RenewalPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 40px;
}

.desktop .RenewalPlanSelection {
    flex-direction: row;
}

.RenewalPlan {
    display: flex;
    align-items: center;
}

.AirRenewalView .DiscountPrice {
    position: absolute;
    margin-top: -20px;
    color: #f8837e;
    font-weight: bold;
    font-size: 1.4em;
}

.AirRenewalView .StrikeThroughPrice {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

.AirRenewalView .DiscountedBadge {
    z-index: 2;
    background-color: #f8837e;
    color: #fff;
    transform: rotate(50deg);
    margin-top: 15px;
    margin-right: -35px;
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 600;
    text-transform: uppercase;
}

.AirRenewalView .DiscountedBadgeContainer {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.desktop .AirRenewalSuggestAssistance .SectionContent {
    padding: 30px;
    position: relative;
}

.AirRenewalSuggestAssistance .ServiceDetailContainer small {
    color: var(--grey-dark-color);
}

.OptionalService h2 {
    color: var(--grey-dark-color);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
}

.OptionalService h3 {
    color: var(--grey-dark-color);
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 1em;
}

.AirRenewalSuggestAssistance .NextButton {
    margin: 20px 0 20px auto;
    width: auto;
}

.AirRenewalSuggestAssistance .ant-radio-wrapper {
    margin-right: 0;
}

.OptionalServiceInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    background-color: #12548A;
    color: white;
}

.small .OptionalServiceInfo, .extrasmall .OptionalServiceInfo {
    flex-wrap: wrap;
    justify-content: center;
}

.small .OptionalServiceInfo>*, .extrasmall .OptionalServiceInfo>* {
    margin-top: 32px;
}

.small .InfoColumn, .extrasmall .InfoColumn {
    width: 100%;
}

.desktop .OptionalServiceInfo {
    min-height: 450px;
    font-size: 16px;
}

.AirRenewalSuggestAssistance .ListItemContainer {
    display: flex;
}

.AirRenewalSuggestAssistance .ListIcon {
    color: #F6CF02;
    margin-top: 2px;
    margin-right: 13px;
}

.OptionalServiceInfo h4 {
    font-size: 1.2em;
    text-transform: uppercase;
    color: #F6CF02;
}

.OptionalServiceInfo .InfoColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.OptionalServiceInfo .AxaLogoContainer {
    margin-top: 24px;
    font-size: 12px;
    text-align: right;
}

.OptionalServiceInfo .AxaLogoContainer img {
    width: 40px;
    height: auto;
    vertical-align: baseline;
}

.AirRenewalSuggestAssistance .ServiceInfoIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.small .AirRenewalSuggestAssistance .ServiceInfoIcon,
.extrasmall .AirRenewalSuggestAssistance .ServiceInfoIcon{
    order: -1;
}

.AirRenewalSuggestAssistance .ServiceInfoIcon img {
    max-width: 200px;
    height: auto;
}

.OptionalServiceSelectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 18px;
}

.OptionalServiceSelection {
    display: flex;
}

.OptionalServiceSelection p {
    margin-right: 16px;
}

.OptionalServiceSelection .ant-checkbox-wrapper {
    margin-left: 12px;
    font-size: 18px;
}

.AirRenewalSuggestAssistance .OptionalServicePrivacy {
    font-size: 14px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.AirRenewalSuggestAssistance .OptionalServicePrivacy p {
    margin: 0;
}

.AirRenewalSuggestAssistance .OptionalServicePrivacy h4, h4.PrivacyHeadTitle {
    font-size: 16px;
    margin-bottom: 0;
}

.ProtectionLevel {
    max-width: 500px;
    text-align: center;
    border: 3px solid #F6CF02;
    padding: 8px 40px;
    border-radius: 4px;
    margin-top: 40px;
}

.ProtectionLevel>div {
    padding: 8px 0;
}

.ProtectionLevel img {
    width: 40px;
    height: auto;
}

.ProtectionRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ProtectionBar>div {
    height: 8px;
    flex-grow: 1;
    background-color: #7EDA57;
    margin: 0 4px;
    border-radius: 2px;
}

.AirClubPrice {
    color: var(--action-primary-color);
}

.OptionalServicePrivacyCheck {
    margin-right: 8px;
}

.AirRenewalSuggestAssistance .AirClubInfoContract{
    display: flex;
    justify-content: flex-end;
}

.AirRenewalSuggestAssistance .AirClubInfoContract a {
    font-size: 16px;
    color: var(--black-color);
    text-decoration: none;
}

.AirRenewalSuggestAssistance .DiscountPrice {
    color: #f8837e;
    font-weight: bold;
}

.AirRenewalSuggestAssistance .StrikeThroughPrice {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

.AirRenewalSuggestAssistance .DiscountedBadge {
    z-index: 2;
    background-color: #f8837e;
    color: #fff;
    transform: rotate(50deg);
    margin-top: -15px;
    margin-right: -8px;
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 600;
    text-transform: uppercase;
}

.AirRenewalSuggestAssistance .DiscountedBadgeContainer {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 75px;
    margin-top: 2px;
}

.AirRenewalSuggestAssistance .OptionalServiceAssistanceContent {
    padding: 0;
    text-align: center;
    position: relative;
}

.AirRenewalSuggestAssistance .OptionalServiceAssistanceContent header {
    background-color: #EFEFEF;
    padding: 8px;
    margin-bottom: 24px;
}

.AirRenewalSuggestAssistance .OptionalServiceAssistanceContent header h2,
.AirRenewalSuggestAssistance .OptionalServiceAssistanceContent header h3
{
    margin-bottom: 0;
    color: var(--action-brand-color);
}

.AirRenewalSuggestAssistance .OptionalServiceAssistanceContent header h2 {
    font-weight: bold;
}

.AirRenewalSuggestAssistance .OptionalServiceAssistanceContent .ProductFeatures {
    margin: auto;
    display: table;
}

.AirRenewalSuggestAssistance .OptionalServiceAssistanceContent .ListItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
}

.AirRenewalSuggestAssistance .OptionalServiceAssistanceContent .ListItemContainer .ListIcon {
    font-weight: bold;
    color: var(--action-brand-color);
    margin-right: 8px;
}

@media (min-width: 1094px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: row;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
    }
}

@media (max-width: 1094px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
    }
    .ThreeColumnView > :nth-child(1) { order: 2; }
    .ThreeColumnView > :nth-child(2) { order: 1; }
    .ThreeColumnView > :nth-child(3) { order: 3; }
}

.AssistanceTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    color: #223239;
}

.AssistanceSubTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    color: #223239;
}

.BaseAssistanceSubTitleContainer {
    display: flex;
    justify-content: center;
}

.SvgImageContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.AssistanceFeatureCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-height: 80px;
    margin: 15px 30px;
    padding: 0 5px 0 5px;
}

.AssistanceFeatureCardContainer :nth-child(1) { flex: 1 1; }
.AssistanceFeatureCardContainer :nth-child(2) { flex: 3 1; }
.AssistanceFeatureCardContainer :nth-child(3) { flex: 1 1; }

.AssistanceFeatureOneColumnComponent {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
}

.AssistanceIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--assistance-icon-container-color);
    border-radius: 12px;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.AssistanceIconColor {
    color: var(--assistance-icon-color);
}

.AssistanceFeatureDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.AssistanceColumnTitle {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
}

.AssistanceColumnFeature {
    font-family: Poppins-Regular, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
}

.AxaIconContainer {
    border-radius: 8px;
    overflow: hidden;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.BaseAssistanceProductPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.BaseAssistanceBuyButton {
    max-width: 335px;
    min-height: 55px;
    height: 55px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.BaseAssistanceDontBuyButton {
    width: 104px;
    min-height: 29px;
    height: 29px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.BaseAssistanceProductPlan {
    display: flex;
    align-items: center;
    margin: 10px 30px;
}

.BaseAssistancePlanPrice {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
}

.ColoredPriceBaseAssistance {
    color: var(--action-brand-color);
    font-weight: 700;
}

.BaseAssistanceCheckboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
}

.BaseAssistanceProceedButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.BaseAssistanceProceedButtonsMobileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.BaseAssistanceDontBuyMobileButton {
    min-height: 55px;
    height: 55px;
    max-width: 104px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    white-space: normal;
}

.generalConditionsCheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PaymentManagementView {
    --light-grey-payment-view: #d0cece;
}

.PaymentManagementView .AddFirstPaymentMethodContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 250px;
    background-color: var(--white-color);
    border: solid 1px var(--light-grey-payment-view);
    border-radius: 25px;
    margin-top: 20px;
    padding: 10px;
}

.PaymentManagementView .ButtonAddPaymentMethod {
    max-width: 400px;
}

.PaymentManagementView .AlreadyPresentPaymentMethodContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    max-width: 700px;
    margin-top: 20px;
}

.PaymentManagementView .PreviousPaymentMethodContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: var(--white-color);
    border-radius: 25px;
    border: solid 1px var(--light-grey-payment-view);
    color: var(--light-grey-payment-view);
    padding: 30px;
}

.PaymentManagementView .ChangePaymentMethodButton {
    max-width: 200px;
    margin-top: 20px;
}

.PaymentManagementView .PaymentMethodDetailsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.PaymentManagementView .PaymentMethodDetailsItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
}

.PaymentManagementView .InputPaymentDetails {
    color: black;
    margin-top: 5px;
    cursor: not-allowed;
}

.PaymentManagementView .InputPaymentDetails {
    color: black;
    margin-top: 5px;
    cursor: not-allowed;
    width: 250px;
}

.PaymentManagementView .PaymentMethodExpirationDateRow >input {
    max-width: 115px;
}

.PaymentManagementView .paymentType {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border-radius: 15px;
    border: solid 1px var(--light-grey-payment-view);
    max-width: 60px;
    margin-top: 5px;
    text-transform: uppercase;
}

.PaymentMethodContainer .PaymentInfo {
    display: flex;
    align-items: center;
    color: var(--action-primary-color);
    margin-top: 20px;
    line-height: 1em;
}

.PaymentMethodPopup .top {
    display: none;
}

.ReviewPayment .ListItemContainer {
    display: flex;
    align-items: center;
}

.PaymentMethodPopup .PaymentMethodContainer {
    border: 1px solid var(--light-grey);
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    margin: 20px 0 40px 0;
}

.PaymentMethodPopup .PaymentMethodContainer .ant-radio-group {
    width: 100%;
}

.ReviewPayment .ListIcon {
    color: #52AF61;
    margin-top: 2px;
    margin-right: 13px;
}

.PaymentMethodPopup h3 {
    color: var(--action-primary-color);
    text-align: center;
}

.PaymentMethodPopup h4 {
    color: var(--action-primary-color);
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.PaymentMethodPopup h4::after {
    content: '';
    border-bottom: 1px solid var(--action-primary-color);
    height: 0;
    margin-left: 8px;
    display: inline-block;
    flex-grow: 10;
}

.PaymentMethodPopup h4::before {
    content: '';
    border-bottom: 1px solid var(--action-primary-color);
    height: 0;
    margin-right: 8px;
    display: inline-block;
    flex-grow: 1;
}

.PaymentMethodPopup .PaymentInfo {
    display: flex;
    align-items: center;
    color: var(--action-primary-color);
    margin-top: 20px;
    line-height: 1em;
}

.PaymentMethodPopup .PaymentInfo .anticon-info-circle{
    color: var(--action-primary-color);
    margin-right: 12px;
    font-size: 18px;
}

.PaymentMethodPopup .defaultPayment {
    color: var(--grey);
}

.InstallerInformationView {

}

.InstallerInformationView .title {
    color: #223239;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.InstallerInformationForm {

}


.InstallerInformationForm .ant-row {
    margin: 20px 0;
}

.InstallerInformationForm .LabelInput .ant-input-disabled {
    color: var(--black-color);
}

.PurchaseAssistanceView .SectionContent {
    padding: 0;
    text-align: center;
    position: relative;
}

.PurchaseAssistanceView header {
    background-color: #EFEFEF;
    padding: 8px;
    margin-bottom: 24px;
}

.PurchaseAssistanceView header h2,
.PurchaseAssistanceView header h3
{
    margin-bottom: 0;
    color: var(--action-brand-color);
}

.PurchaseAssistanceView header h2 {
    font-weight: bold;
}

.PurchaseAssistanceView .PlanPrice {
    margin-left: 8px;
    text-align: left;
    margin-bottom: 24px ;
}

.PurchaseAssistanceView .PlanPrice h2{
    color: var(--dark-grey);
    margin-bottom: 0;
    font-size: 2em;
    font-weight: bold;
}

.PurchaseAssistanceView .PlanPrice h2 .taxes{
    font-size: 14px;
    font-weight: normal;
}

.PurchaseAssistanceView .PlanPrice h3{
    color: var(--dark-grey);
    margin-bottom: 0;
    font-size: 1.2em;
    line-height: 0.7em;
    font-weight: bold;
}

.PurchaseAssistanceView .ProductFeatures {
    margin: auto;
    display: table;
}

.PurchaseAssistanceView .ListItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
}

.PurchaseAssistanceView .ListItemContainer .ListIcon {
    font-weight: bold;
    color: var(--action-brand-color);
    margin-right: 8px;
}

.PurchaseAssistanceView .ReviewFooter {
    padding-top: 32px;
}

.desktop .PurchaseAssistanceView .ReviewFooter {
    padding: 32px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.PurchaseAssistanceView .ReviewFooter .ReviewInfo {
    color: var(--dark-grey);
    font-weight: 600;
    font-size: 0.95em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .PurchaseAssistanceView .ReviewFooter .ReviewInfo {
    margin-right: 16px;
}

.small .PurchaseAssistanceView .ReviewFooter .ReviewInfo,
.extrasmall .PurchaseAssistanceView .ReviewFooter .ReviewInfo {
    margin-bottom: 16px;
}

.PurchaseAssistanceView .ReviewFooter .ReviewInfo .ReviewFooterIcon {
    color: #FB8680;
    margin-right: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.desktop .PurchaseAssistanceView .ReviewFooter .MyPageButton {
    width: 200px;
}

.ProductPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 40px;
}

.desktop .ProductPlanSelection {
    flex-direction: row;
}

.ProductPlan {
    display: flex;
    align-items: center;
}

.PurchaseAssistanceView .DiscountPrice {
    position: absolute;
    margin-top: -20px;
    color: #f8837e;
    font-weight: bold;
    font-size: 1.4em;
}

.PurchaseAssistanceView .StrikeThroughPrice {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
}

.PurchaseAssistanceView .DiscountedBadge {
    z-index: 2;
    background-color: #f8837e;
    color: #fff;
    transform: rotate(50deg);
    margin-top: 15px;
    margin-right: -35px;
    width: 130px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 600;
    text-transform: uppercase;
}

.PurchaseAssistanceView .DiscountedBadgeContainer {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

@media (min-width: 1094px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: row;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
    }
}

@media (max-width: 1094px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;
    }
    .ThreeColumnView > :nth-child(1) { order: 2; }
    .ThreeColumnView > :nth-child(2) { order: 1; }
    .ThreeColumnView > :nth-child(3) { order: 3; }
}

.AssistanceTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    color: #223239;
}

.AssistanceSubTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    color: #223239;
}

.SvgImageContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.AssistanceFeatureCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-height: 80px;
    margin: 15px 30px;
    padding: 0 5px 0 5px;
}

.AssistanceFeatureCardContainer :nth-child(1) { flex: 1 1; }
.AssistanceFeatureCardContainer :nth-child(2) { flex: 3 1; }
.AssistanceFeatureCardContainer :nth-child(3) { flex: 1 1; }

.AssistanceFeatureOneColumnComponent {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
}

.AssistanceIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--assistance-icon-container-color);
    border-radius: 12px;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.AssistanceIconColor {
    color: var(--assistance-icon-color);
}

.AssistanceFeatureDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.AssistanceColumnTitle {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
}

.AssistanceColumnFeature {
    font-family: Poppins-Regular, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
}

.AxaIconContainer {
    border-radius: 8px;
    overflow: hidden;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.BaseAssistanceProductPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.BaseAssistanceBuyButton {
    width: 335px;
    min-height: 55px;
    height: 55px;
}

.BaseAssistanceProductPlan {
    display: flex;
    align-items: center;
    margin: 10px 30px;
}

.BaseAssistancePlanPrice {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
}

.ColoredPriceBaseAssistance {
    color: var(--action-brand-color);
    font-weight: 700;
}


.BaseAssistanceCheckboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
}

.SuggestedAdditionalServices h2 {
    color: var(--grey-dark-color);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
}

.SuggestedAdditionalServices h3 {
    color: var(--grey-dark-color);
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 1em;
}

.SuggestedAdditionalServicesInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    background-color: #12548A;
    color: white;
}

.small .SuggestedAdditionalServicesInfo, .extrasmall .SuggestedAdditionalServicesInfo {
    flex-wrap: wrap;
    justify-content: center;
}

.small .SuggestedAdditionalServicesInfo>*, .extrasmall .SuggestedAdditionalServicesInfo>* {
    margin-top: 32px;
}

.small .InfoColumn, .extrasmall .InfoColumn {
    width: 100%;
}

.desktop .SuggestedAdditionalServicesInfo {
    min-height: 450px;
    font-size: 16px;
}

.SuggestedAdditionalServicesInfo h4 {
    font-size: 1.2em;
    text-transform: uppercase;
    color: #F6CF02;
}

.SuggestedAdditionalServicesInfo .InfoColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.SuggestedAdditionalServicesInfo .AxaLogoContainer {
    margin-top: 24px;
    font-size: 12px;
    text-align: right;
}

.SuggestedAdditionalServicesInfo .AxaLogoContainer img {
    width: 40px;
    height: auto;
    vertical-align: baseline;
}

.SuggestedAdditionalServicesSelectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 18px;
}

.SuggestedAdditionalServicesSelection {
    display: flex;
}

.SuggestedAdditionalServicesSelection p {
    margin-right: 16px;
}

.SuggestedAdditionalServicesSelection .ant-checkbox-wrapper {
    margin-left: 12px;
    font-size: 18px;
}

.ProtectionLevel {
    max-width: 500px;
    text-align: center;
    border: 3px solid #F6CF02;
    padding: 8px 40px;
    border-radius: 4px;
    margin-top: 40px;
}

.ProtectionLevel>div {
    padding: 8px 0;
}

.ProtectionLevel img {
    width: 40px;
    height: auto;
}

.ProtectionRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ProtectionBar>div {
    height: 8px;
    flex-grow: 1;
    background-color: #7EDA57;
    margin: 0 4px;
    border-radius: 2px;
}

.AirClubPrice {
    color: var(--action-primary-color);
}

.SuggestedAdditionalServicesPrivacyCheck {
    margin-right: 8px;
}

@media (min-width: 736px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: row;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

@media (max-width: 736px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.SuggestedAdditionalServicesSelectionTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    color: #223239;
}

.SuggestedAdditionalServicesTitleBrandColor {
    text-transform: uppercase;
    color: var(--action-brand-color);
}

.SuggestedAdditionalServicesSubTitleRedColor {
    color: #FB7670;
}

.SuggestedAdditionalServicesSubTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    color: #223239;
}

.SvgImageContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.SuggestedAdditionalServicesFeatureCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-height: 80px;
    margin: 15px 30px;
    padding: 0 5px 0 5px;
}

.SuggestedAdditionalServicesFeatureCardContainer :nth-child(1) { flex: 1 1; }
.SuggestedAdditionalServicesFeatureCardContainer :nth-child(2) { flex: 3 1; }
.SuggestedAdditionalServicesFeatureCardContainer :nth-child(3) { flex: 1 1; }

.SuggestedAdditionalServicesFeatureOneColumnComponent {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
}

.SuggestedAdditionalServicesIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--SuggestedAdditionalServices-icon-container-color);
    border-radius: 12px;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.SuggestedAdditionalServicesIconColor {
    color: var(--SuggestedAdditionalServices-icon-color);
}

.SuggestedAdditionalServicesFeatureDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.SuggestedAdditionalServicesColumnTitle {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
}

.SuggestedAdditionalServicesColumnFeature {
    font-family: Poppins-Regular, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
}

.AxaIconContainer {
    border-radius: 8px;
    overflow: hidden;
    max-width: 30px;
    width: 30px;
    max-height: 30px;
    height: 30px;
}

.SuggestedAdditionalServicesProductPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.SuggestedAdditionalServicesBuyButton {
    max-width: 335px;
    min-height: 55px;
    height: 55px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.SuggestedAdditionalServicesDontBuyButton {
    width: 104px;
    min-height: 29px;
    height: 29px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.SuggestedAdditionalServicesProceedButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SuggestedAdditionalServicesProceedButtonsMobileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.SuggestedAdditionalServicesDontBuyMobileButton {
    min-height: 55px;
    height: 55px;
    max-width: 104px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    white-space: normal;
}

.generalConditionsCheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.InformativeNotesContract{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}
.InformativeNotesContract a {
    font-size: 16px;
    color: var(--black-color);
    text-decoration: none;
}

.AdditionalServicesConditionCheckboxesPrivacyCheck {
    margin-right: 8px;
}

.ProductCardWithSelectableRatePlanContainer {
    width: 300px;
    height: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.ProductCardWithSelectableRatePlanInformationContainer {
    position: relative;
    width: 250px;
    height: 380px;
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.15);
    border-width: 1px;
    border-style: solid;
    border-color: #D8D9E0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.ProductCardWithSelectableRatePlanBadgeContainer {
    position: absolute;
    top: -31px;
    left: -1px;
    align-self: start;
    background-color: #FFAC30;
    color: white;
    text-transform: uppercase;
    width: 170px;
    height: 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
    border-width: 1px;
    border-bottom-width: 0;
    border-style: solid;
    border-color: #D8D9E0;
}

.ProductCardWithSelectableRateSubTitleInformationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 70px;
    font-size: 14px;
    max-width: 200px;
    color: #223239;
}

.ProductCardWithSelectableRatePlanTitleContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-transform: uppercase;
    color: #223239;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 15px;
    max-width: 170px;
    height: 50px;
}

.ProductCardWithSelectableRatePlanImageContainer {
    position: absolute;
    top: -50px;
    left: 200px;
}

.ProductCardWithSelectableRatePlanAdditionalTitleIconContainer {
    position: absolute;
    left: -30px;
}

.highlighted {
    border-color: var(--action-brand-color);
}

.ProductCardWithSelectableRatePlanPriceOutsideContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ProductCardWithSelectableRatePlanPriceContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 135px;
    height: 55px;
    border-radius: 3px;
    color: var(--action-brand-color);
    background-color: var(--light-primary-color);
    border-width: 1px;
    border-style: solid;
    border-color: #D8D9E0;
}

.ProductCardWithSelectableRatePlanPriceContainerHighlighted {
    background-color: var(--action-brand-color);
    border-width: 0;
    color: white;
}

.ProductCardWithSelectableRatePlanPriceDiscountedContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ProductCardWithSelectableRatePlanPriceDiscounted {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
}

.ProductCardWithSelectableRatePlanPriceOriginal {
    font-weight: 400;
    letter-spacing: 1px;
    text-decoration: line-through;
    color: #223239;
}

.ProductCardWithSelectableRatePlanPriceOriginalHighlighted {
    color: white;
}

.ProductCardWithSelectableRatePlanFeatureContainer {
    border-top: 1px solid #D8D9E0;
    padding: 10px 0 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 135px;
    width: 100%;
    color: #223239;
    font-size: 12px;
}

.ProductCardWithSelectableRatePlanChosePlanContainer {
    margin-top: 20px;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #223239;
    font-size: 14px;
}

.ColoredPriceProductCardWithSelectableRatePlan {
    color: var(--action-brand-color);
    font-weight: 700;
}

.ProductCardWithSelectableRatePlanCheckboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
}

.ProductCardWithSelectableRatePlanProductPlan {
    display: flex;
    align-items: center;
    margin: 10px 30px;
}

.ProductCardWithSelectableRatePlanPlanPrice {
    font-family: Poppins-Regular, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
}

.ProductCardWithSelectableRatePlan {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.ProductCardWithSelectableRatePlanFeature {
    font-size: 12px;
}

.BuyAdditionalService h2 {
    color: var(--grey-dark-color);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
}

.BuyAdditionalService h3 {
    color: var(--grey-dark-color);
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 1em;
}

.BuyAdditionalServiceInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 30px;
    background-color: #12548A;
    color: white;
}

.small .BuyAdditionalServiceInfo, .extrasmall .BuyAdditionalServiceInfo {
    flex-wrap: wrap;
    justify-content: center;
}

.small .BuyAdditionalServiceInfo>*, .extrasmall .BuyAdditionalServiceInfo>* {
    margin-top: 32px;
}

.small .InfoColumn, .extrasmall .InfoColumn {
    width: 100%;
}

.desktop .BuyAdditionalServiceInfo {
    min-height: 450px;
    font-size: 16px;
}

.BuyAdditionalServiceInfo h4 {
    font-size: 1.2em;
    text-transform: uppercase;
    color: #F6CF02;
}

.BuyAdditionalServiceInfo .InfoColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.BuyAdditionalServiceInfo .AxaLogoContainer {
    margin-top: 24px;
    font-size: 12px;
    text-align: right;
}

.BuyAdditionalServiceInfo .AxaLogoContainer img {
    width: 40px;
    height: auto;
    vertical-align: baseline;
}

.BuyAdditionalServiceSelectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 18px;
}

.BuyAdditionalServiceSelection {
    display: flex;
}

.BuyAdditionalServiceSelection p {
    margin-right: 16px;
}

.BuyAdditionalServiceSelection .ant-checkbox-wrapper {
    margin-left: 12px;
    font-size: 18px;
}

.ProtectionLevel {
    max-width: 500px;
    text-align: center;
    border: 3px solid #F6CF02;
    padding: 8px 40px;
    border-radius: 4px;
    margin-top: 40px;
}

.ProtectionLevel>div {
    padding: 8px 0;
}

.ProtectionLevel img {
    width: 40px;
    height: auto;
}

.ProtectionRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ProtectionBar>div {
    height: 8px;
    flex-grow: 1;
    background-color: #7EDA57;
    margin: 0 4px;
    border-radius: 2px;
}

.AirClubPrice {
    color: var(--action-primary-color);
}

.BuyAdditionalServicePrivacyCheck {
    margin-right: 8px;
}

@media (min-width: 736px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: row;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

@media (max-width: 736px) {
    .ThreeColumnView {
        display: flex;
        flex-direction: column;
        align-content: center;
    }

    .OneColumnComponent {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

.BuyAdditionalServiceSelectionTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    color: #223239;
}

.BuyAdditionalServiceTitleBrandColor {
    color: var(--action-brand-color);
}

.BuyAdditionalServiceSubTitleRedColor {
    color: #FB7670;
}

.BuyAdditionalServiceSubTitle {
    font-family: Poppins-Regular, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 18px;
    color: #223239;
}

.BuyAdditionalServiceSubTitleContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-weight: 400;
    color: #223239;
    font-size: 16px;
    text-align: center;
    padding-left: 15%;
    padding-right: 15%;
}

.SvgImageContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.BuyAdditionalServiceFeatureCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    background: #FFFFFF;
    box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-height: 80px;
    margin: 15px 30px;
    padding: 0 5px 0 5px;
}

.BuyAdditionalServiceFeatureCardContainer :nth-child(1) { flex: 1 1; }
.BuyAdditionalServiceFeatureCardContainer :nth-child(2) { flex: 3 1; }
.BuyAdditionalServiceFeatureCardContainer :nth-child(3) { flex: 1 1; }

.BuyAdditionalServiceFeatureOneColumnComponent {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
}

.BuyAdditionalServiceIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--BuyAdditionalService-icon-container-color);
    border-radius: 12px;
    max-width: 46px;
    width: 46px;
    max-height: 46px;
    height: 46px;
}

.BuyAdditionalServiceIconColor {
    color: var(--BuyAdditionalService-icon-color);
}

.BuyAdditionalServiceFeatureDescriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.BuyAdditionalServiceColumnTitle {
    font-family: Poppins-Regular, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 25px;
    text-align: center;
}

.BuyAdditionalServiceColumnFeature {
    font-family: Poppins-Regular, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
}

.BuyAdditionalServiceProductPlanSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.BuyAdditionalServiceBuyButton {
    max-width: 335px;
    min-height: 55px;
    height: 55px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.BuyAdditionalServiceDontBuyButton {
    width: 104px;
    min-height: 29px;
    height: 29px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

.BuyAdditionalServiceProceedButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.BuyAdditionalServiceProceedButtonsMobileContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.BuyAdditionalServiceDontBuyMobileButton {
    min-height: 55px;
    height: 55px;
    max-width: 104px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    white-space: normal;
}

.generalConditionsCheckboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.InformativeNotesContract{
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}
.InformativeNotesContract a {
    font-size: 16px;
    color: var(--black-color);
    text-decoration: none;
}

.AuthMobileContainer {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--black-color);
  font-size: 16px;
}

.AuthMobileContainer > * {
  display: flex;
  justify-content: center;
}

.MobileMarketplaceLink {
}

.MobileMarketplaceLink > * {
  cursor: pointer;
}

.MobileLogin .LoginStepFirst {
  flex-grow: 1;
  margin: 16px;
}

.MobileLogin .LoginStepFirst > .TwoColumns {
  display: flex;
  justify-content: space-between;
}
.MobileLogin .LoginStepFirst > .TwoColumns > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MobileLogin .MobileMarketplaceLink {
  margin: 40px 0;
  display: flex;
  justify-content: space-evenly;
}

.AuthDesktopContainer {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  color: var(--black-color);
  font-size: 16px;
}

.AuthDesktopContainer .LoginVertical {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AuthDesktopContainer .ImageLayer {
  background-image: url(/static/media/LoginLayer.de008f68.png);
  background-size: cover;
  background-position: center center;
  color: var(--action-primary-color);
}

.AuthDesktopContainer .FormLayer {
  background-color: var(--magic1-color);
}

.AuthDesktopContainer .LoginVertical > *:nth-child(2) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DesktopLogin .LoginStepFirst {
  width: 400px;
  margin: 16px;
}

.DesktopLogin .LoginStepFirst > .TwoColumns {
  display: flex;
  justify-content: space-between;
}
.DesktopLogin .LoginStepFirst > .TwoColumns > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DesktopLogin .MobileMarketplaceLink {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
}

.MobileForgottenPassword .RecoverySteps {
  flex-grow: 1;
  margin: 16px;
}

.MobileForgottenPassword .RecoverySteps > .TwoColumns {
  display: flex;
  justify-content: space-between;
}
.MobileForgottenPassword .RecoverySteps > .TwoColumns > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DesktopForgottenPassword .RecoverySteps {
  width: 400px;
  margin: 16px;
}

.DesktopForgottenPassword .RecoverySteps > .TwoColumns {
  display: flex;
  justify-content: space-between;
}
.DesktopForgottenPassword .RecoverySteps > .TwoColumns > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

