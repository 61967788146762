@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
  margin: 0;
  padding: 0;
  font-family: 'Work Sans', sans-serif;
  --white-color: #ffffff;
  --black-color: #191d3a; /* 25, 29, 58 */
  --grey-black-20-color: rgba(25, 29, 58, 0.2);
  --action-primary-color: #56d3cb;
  --light-primary-color: #d2f4ed;
  --action-success-color: #00f7bf;
  --light-success-color: #d2f4ed;
  --action-danger-color: #ff5d58;
  --light-danger-color: #fbe1e1;
  --action-info-color: #E6E6E6;
  --light-info-color: #d6f4f7;
  --action-warning-color: #ffb307;
  --light-warning-color: #f8f5d5;
  --action-custom-color: #56d3cb;
  --light-custom-color: #e7e7f9;
  --grey-semi-color: #e0e2e9;
  --grey-light-color: #eeeff1;
  --grey-light-color-50: rgba(238,239,241,0.5);
  --grey-grey-color: #c5c4cf;
  --grey-dark-color: #9393a0;
  --magic1-color: #f5f9fb;
  --action-brand-color: #2bb5ae;
  --action-grey-color: #9393a0;
  --light-grey-color: #ceced4;
  --action-delete-color: #FA8586;
  --action-gray-color: #B1B8B7;
  --light-delete-color: #ceced4;

  --secondary-color: #2fa0a0; /*rgb 86, 219, 209*/
  --tertiary-color: #f7fcfc; /*rgb 232, 255, 255*/

  --red-dtc-color: #ff5d58;
  --orange-dtc-color: #ffb307;
  --battery-warning-color: #ffb307;
  --alarm-color: #a175fc;
  --maintenance-color: #3195fe;
  --air-salmon-color: #f8837e;

  --assistance-icon-container-color: #FFFBED;
  --assistance-icon-color: #FFE074;

  --yellow: #ffdd07;
  --green: #22c437;

  --grey: #c9c9c9;
  --grey-2: #ceced4;
  --light-grey: #dcdcdc;
  --light-grey-2: #e6e6e6;
  --light-grey-3: #efeff1;
  --light-grey-4: #f0f0f0;
  --dark-grey: #6f7070;
  --dark-grey-2: #747575;
  --heliotrope: #A175FC;

  --bg-color: var(--light-grey-3);
  --bg-color-2: #202020;
  --bg-color-2-a05: rgba(32, 32, 32, 0.5);

  --engine-on: #2fa0a0;
  --engine-off: #819e9e;
  --lock-on: #53b3e3;
  --lock-off: #819e9e;

  --header-height: 60px;

  --selected-color: #49c6be;
  --disabled-color: #6f7070;
  --selected-tab-color: #56d3cb;

  --subheader-height: 100px;
  --health-filter-height: 80px;

  --header-with-subheader-height: calc(var(--header-height) + var(--subheader-height));

  --button-border-radius: 18px;
  --diagnostics-filters-offset: -80px;





  --permanent-left-width: 264px;
}
