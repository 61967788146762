.AdvertisingRCABanner-container {
    background-color: #2BB5AE;
    border-radius: 15px;
    color: white;
    text-align: center;
    padding: 32px 21px;
    font-size: 14px;
    font-weight: 300;
    width: 311px;
}
